import {Helmet as ReactHelmet} from "react-helmet";
import PropTypes from "prop-types";
import React from "react";
import shareImage from "@images/share-image-michelle.png";
import androidIcon from "@images/android-icon-192x192.png";
import favicon16 from "@images/favicon-16x16.png";
import favicon32 from "@images/favicon-32x32.png";
import favicon96 from "@images/favicon-96x96.png";
import menu from "@config/menu";
import {useLocation} from "@reach/router";
import {siteMetadata} from "../../gatsby-config";

import blog1ShareImage from "@images/blog1-share-image.jpg";
import blog2ShareImage from "@images/blog2-share-image.jpg";
import blog3ShareImage from "@images/blog3-share-image.jpg";

const titles = menu.reduce((acc, menuItem) => {
    if (menuItem.url === "/") {
        return acc;
    }
    acc[menuItem.url] = menuItem.title;
    if (Array.isArray(menuItem.submenu)) {
        menuItem.submenu.forEach(submenuItem => {
            acc[submenuItem.url] = submenuItem.title;
        });
    }
    return acc;
}, {
    "/certified-medical-center/revive-clinic-center": "獲認證的醫療中心",
});

export default function Helmet(props) {
    const {title, description, keywords, imageWidth, imageHeight, children} = props;
    let {image} = props;
    let {pathname} = useLocation();

    if (pathname.substr(-1) === "/") {
        pathname = pathname.substr(0, pathname.length - 1);
    }
    const showTitle = typeof title === "string" ? title : titles[pathname];
    let displayTitle = showTitle ? `${showTitle} | BELOTERO` : "BELOTERO";

    let pathDescription = "";
    let pathKeywords = keywords;

    // console.log("Pathname: ", pathname);

    switch (pathname) {
        case "": {
            displayTitle = "BELOTERO透明質酸彩虹針｜香港醫學美容透明質酸療程";
            pathDescription = "BELOTERO®一系列5款透明質酸彩虹針，照顧不同年齡層的肌膚需要及理想塑顏效果，療程有效平滑膚質、保濕盈潤、提升輪廓及立體。立即了解更多。";
            pathKeywords = "彩虹針, 透明質酸, 香港 透明 質 酸, 透明質酸療程, 醫學美容療程, 透明質酸是什麼";
            break;
        }

        // case "/about/cpm": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        case "/about/belotero-hyaluronic-acid": {
            displayTitle = "認識 BELOTERO® 透明質酸牌子｜透明質酸注射｜BELOTERO";
            pathDescription = "Belotero®透明質酸以獨家CPM技術製造，獲全球多個醫學權威認可，與皮膚無瑕融合，透過施打透明質酸消除法令紋、提升蘋果肌，緊緻下巴，效果自然持久。";
            pathKeywords = "透明質酸下巴, 打 法令紋, 打 蘋果 肌, 打透明質酸, 透明質酸針, 透明質酸牌子, 透明質酸注射, 透明質酸 填充";
            break;
        }

        case "/about/belotero-revive": {
            displayTitle = "香港 BELOTERO REVIVE 水光針｜了解打水光針效果｜BELOTERO";
            pathDescription = "BELOTERO® REVIVE水光針，採用獨有保濕鎖水配方，特別加入甘油成份，強化保濕的效果能夠活化肌底，從肌底保濕，提升肌膚水潤，因此在香港大受好評！";
            pathKeywords = "打水光針, 水光針, 水光針香港, 水 光 針 效果, 水 光 針 後遺症, 水光針副作用";
            break;
        }

        case "/treatment-result/safety": {
            displayTitle = "打透明質酸需注意什麼？注射後會有副作用嗎？｜BELOTERO";
            pathDescription = "BELOTERO®透明質酸是優質的透明質酸產品系列，提供極佳的肌膚融合配方，效果自然溫和持久。立即點擊，了解更多有關透明質酸功效及注意事項。";
            pathKeywords = "打透明質酸注意, 透明質酸副作用, 透明質酸功效, 透明質酸用法, 注射透明質酸副作用";
            break;
        }

        // case "/treatment-result/ageing/": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        // case "/treatment-result/safety/": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        // case "/treatment-result/treatment-effect/": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        // case "/treatment-result/faq/": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        // case "/expert-advice/": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        case "/expert-advice/blog1": {
            displayTitle = "一文認識 Belotero®透明質酸系列：5款產品分析全攻略";
            pathDescription = "瑞士製造的Belotero®透明質酸系列共有5款產品，迎合您肌膚的各種需要，能為您重拾水潤肌膚、撫平細紋、改善臉部凹陷及提升輪廓，效果自然持久。";
            image = blog1ShareImage;
            break;
        }

        case "/expert-advice/blog2": {
            displayTitle = "水光針有無用？多久打一次？水光針懶人包一次教你選！";
            pathDescription = "平常使用護膚品，當中的透明質酸成分難以滲入皮膚底層。想讓皮膚有效地重拾水嫩和彈性，可考慮打水光針。以下為你詳細分析其原理、功效和注意事項等。";
            image = blog2ShareImage;
            break;
        }

        case "/expert-advice/blog3": {
            displayTitle = "甚麼是蘋果肌?為何會下垂？教你 5大方法提升蘋果肌！";
            pathDescription = "蘋果肌下垂，頓時令人顯老10年。本文將與大家一起來深入探討蘋果肌下垂原因，並分享5個提升蘋果肌的方法。立即閱讀。";
            image = blog3ShareImage;
            break;
        }
        // case "/medias-choice": {
            // displayTitle = "";
            // pathDescription = "";
            // break;
        // }

        // case "/certified-medical-center/":
        // case "/certified-medical-center/clinic-center/": {
            // displayTitle = "";
            // pathDescription = "";
            // pathKeywords = "";
            // break;
        // }

        // case "/certified-medical-center/revive-clinic-center/": {
        //     break;
        // }

        default: {
            pathDescription = description;
            break;
        }
    }

    const fullPath = siteMetadata.siteUrl + pathname + "/";
    // console.log("path: ", pathname);

    return (
        <ReactHelmet>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8"
                    data-domain-script="d733c12d-5587-4c52-843c-271cf5819e2d"></script>
            <script type="text/javascript">{"function OptanonWrapper(){}"}</script>

            {children}

            <meta charSet="utf-8"/>

            {/* Canonical */}
            <link rel="canonical" href={fullPath}/>

            {/* Title tags */}
            <title>{displayTitle}</title>
            <meta name="title" property="og:title" content={displayTitle}/>

            {/* Description tags */}
            {/*<meta name="description" property="og:description" content={description} />*/}
            <meta name="description" property="og:description" content={pathDescription}/>

            {/* Keywords tags */}
            {/*<meta name="keywords" property="keywords" content={keywords}/>*/}
            <meta name="keywords" property="keywords" content={pathKeywords}/>

            {/* Image tags (optional) */}
            {image ? <meta name="image" property="og:image" content={"https://www.belotero.com.hk" + image}/> : null}
            {image && imageWidth ? <meta name="image:width" property="og:image:width" content={imageWidth}/> : null}
            {image && imageHeight ? <meta name="image:height" property="og:image:height" content={imageHeight}/> : null}

            {/* URL */}
            <meta name="url" property="og:url" content={fullPath}/>

            <link rel="icon" type="image/png" sizes="192x192" href={androidIcon}/>
            <link rel="icon" type="image/png" sizes="16x16" href={favicon16}/>
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32}/>
            <link rel="icon" type="image/png" sizes="96x96" href={favicon96}/>
            {/*<link rel="canonical" href={fullPath} />*/}
        </ReactHelmet>
    );
}

Helmet.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    image: PropTypes.string,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    children: PropTypes.node,
};

Helmet.defaultProps = {
    description: "BELOTERO®透明質酸結構與肌膚豐盈保濕成分相近，以專利CPM技術製造，獲全球多個醫學權威認可，與皮膚無瑕融合，撫平細紋、皺紋和提升輪廓，效果自然持久。",
    keywords: ["Merz", "BELOTERO", "玻尿酸", "透明質酸", "保濕針", "蘋果肌", "下巴", "隆鼻", "豐滿前額", "太陽穴", "淚溝", "皺紋", "去皺", "虎紋", "魚眉紋", "彩虹針", "彩虹透明質酸", "醫學美容", "保濕"].join(", "),
    children: null,
    image: shareImage,
    imageWidth: 1200,
    imageHeight: 630,
};
