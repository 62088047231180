module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Users/nathan/Documents/belotero.com.hk/src/partials/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156247939-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5JBJVWF","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#ffffff","icon":"src/images/android-icon-192x192.png","icons":[{"sizes":"57x57","src":"src/images/apple-icon-57x57.png","type":"image/png"},{"sizes":"60x60","src":"src/images/apple-icon-60x60.png","type":"image/png"},{"sizes":"72x72","src":"src/images/apple-icon-72x72.png","type":"image/png"},{"sizes":"76x76","src":"src/images/apple-icon-76x76.png","type":"image/png"},{"sizes":"114x114","src":"src/images/apple-icon-114x114.png","type":"image/png"},{"sizes":"120x120","src":"src/images/apple-icon-120x120.png","type":"image/png"},{"sizes":"144x144","src":"src/images/apple-icon-144x144.png","type":"image/png"},{"sizes":"152x152","src":"src/images/apple-icon-152x152.png","type":"image/png"},{"sizes":"180x180","src":"src/images/apple-icon-180x180.png","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33fa5a72268c66879350dd462e684ec7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
